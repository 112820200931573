<template>
  <div class="garden-group-wrapper">
    <div v-if="!mobile" class="plant-icon d-none d-xl-flex ml-0 mr-2">
        <ngs-icon
          v-if="treeIcon"
          :tag="treeIcon"
          :showTooltip="false"/>
    </div>
    <div class="group-info">
      <span>{{ isParentGarden ? 'Group of gardens' : 'Garden'}}</span>
      <div
        class="group-dropdown-wrapper"
        :class="{ 'd-none d-xl-flex': !mobile }"
        v-if="!isParentGarden && garden.group_parent_id"
      >
        <font-awesome-icon size="xs" class="circle-icon mx-1" :icon="['fas', 'circle']"/>
        <span class="mr-1">Part of</span>
        <b-dropdown dropright size="sm" id="group-dropdown" no-caret>
          <template #button-content>
            <span>Group</span>
            <font-awesome-icon size="xs" class="down-icon" :icon="['fas', 'chevron-circle-down']"/>
          </template>
          <b-dropdown-item
            v-for="(sibling, index) in gardenGroup"
            :key="index"
            :class="sibling.name === garden.name ? 'current-garden' : ''"
          >
            {{ sibling.name }}
          </b-dropdown-item>
        </b-dropdown>
      </div>
    </div>
    <div v-if="!mobile" class="plant-icon d-flex d-xl-none ml-1 mr-0">
      <ngs-icon
        v-if="treeIcon"
        :tag="treeIcon"
        :showTooltip="false"/>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import NgsIcon from '@/components/ngsIcons/NgsIcon';

export default {
  name: 'GardenGroupInfo',
  components: { NgsIcon },
  props: {
    garden: {
      type: Object,
      required: true,
    },
    mobile: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    ...mapGetters('ngsicons', [
      'findIconByTag',
    ]),
    treeIcon() {
      return this.isParentGarden ? this.findIconByTag('flowers') : this.findIconByTag('plants');
    },
    gardenGroup() {
      if (!this.garden.same_group_gardens) {
        return [];
      }

      const gardens = this.garden.same_group_gardens.map(({ name }) => { return { name } });
      gardens.push({ name: this.garden.name });

      return gardens;
    },
    isParentGarden() {
      return this.garden.garden_type_id === 2;
    },
  },
}
</script>

<style lang="scss">
.garden-group-wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 4px;

  .plant-icon {
    font-size: 16px;
    color: black;
    background-color: white;
    width: 28px;
    height: 28px;
    padding: 5px;
    border-radius: 15px;
    display: flex;
    align-items: center;
    justify-content: center;

    > svg {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
    }
  }

  .group-info {
    display: flex;
    align-items: center;

    & * {
      text-transform: uppercase;
      font-weight: 600;
      font-size: 12px;
    }
  }
}
</style>
