<template>
  <div class="opening-cards-wrapper">
    <opening-card
      v-for="(opening, index) in openings"
      class="mb-2"
      :key="index"
      :opening="opening"
      :hideRefreshments="hideRefreshments"
      :hideAdmission="hideAdmission"
      :hideOpeningTimes="hideOpeningTimes"
    >
      <template v-slot:card-description>
        <slot name="description" v-bind:model="opening"></slot>
      </template>
      <template v-slot:card-button>
        <slot name="button" v-bind:model="opening"></slot>
      </template>
    </opening-card>
  </div>
</template>

<script>
import OpeningCard from '@/components/gardenDetails/detailTabs/openings/OpeningCard';
import DataFormattingMixin from '@/components/mixins/DataFormattingMixin';

export default {
  name: 'OpeningCards',
  mixins: [DataFormattingMixin],
  props: {
    openings: {
      type: Array,
      required: true,
    },
    hideRefreshments: {
      type: Boolean,
      default: false,
    },
    hideAdmission: {
      type: Boolean,
      default: false,
    },
    hideOpeningTimes: {
      type: Boolean,
      default: false,
    },
  },

  components: {
    OpeningCard,
  },
}
</script>
