<template>
  <GoogleMapLoader
    :mapConfig="mapConfig"
    :apiKey="apiKey"
    :google="google"
  >
    <template slot-scope="{ google, map }">
      <GoogleMapMarker
        :garden="garden"
        :google="google"
        :map="map"
      />
    </template>
  </GoogleMapLoader>
</template>

<script>
import { mapGetters } from 'vuex'

import GoogleMapsApiLoader from 'google-maps-api-loader'
import eventBus from '@/services/eventBus'

import GoogleMapLoader from '@/components/map/GoogleMapLoader'
import GoogleMapMarker from '@/components/map/GoogleMapMarker';

import { mapSettings } from '@/components/map/constants/mapSettings'

export default {
  name: 'GardenMapWrapper',

  components: {
    GoogleMapLoader,
    GoogleMapMarker,
  },

  data: () => ({
    apiKey: process.env.VUE_APP_GOOGLE_MAPS_API_KEY,
    google: null,
    initialiseMap: false,
    mapCenter: {
      lat: 0,
      lng: 0,
    },
    markers: [
      {
        id: 'a',
        location: {
          lat: 51,
          lng: -0,
        },
      },
    ],
  }),

  async mounted() {
    const googleMapApi = await GoogleMapsApiLoader({
      apiKey: this.apiKey,
    })

    this.google = googleMapApi
  },

  computed: {
    ...mapGetters('garden', [
      'garden',
    ]),

    mapConfig() {
      return {
        ...mapSettings,
        center: this.mapCenter,
        zoom: 10,
        disableDefaultUI: true,
        panControlOptions: false,
        panControl: false,
        zoomControl: false,
        draggable: false,
      }
    },
  },

  watch: {
    async garden(value) {
      if (value && this.google) {
        await this.getMapCenter()

        if (!this.initialiseMap) {
          this.initialiseMap = true
        }
      }
    },
    async google(value) {
      if (value && this.garden) {
        await this.getMapCenter()

        if (!this.initialiseMap) {
          this.initialiseMap = true
        }
      }
    },

    initialiseMap(value) {
      if (value && this.mapCenter) {
        eventBus.$emit('initialize-map')
      }
    },
  },

  methods: {
    getMapCenter() {
      this.mapCenter = {
        lat: parseFloat(this.garden.latitude),
        lng: parseFloat(this.garden.longitude),
      }
    },
  },
}
</script>
