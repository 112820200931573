<template>
  <b-row class="details-wrapper details m-0" ref="detailsWrapper">
    <b-col v-if="garden && !loading">
      <b-row>
        <b-col
          id="detail-section"
          class="detail-section p-0"
          ref="detailSection"
          cols="12"
          lg="6"
        >
          <detail-hero :garden="garden" ref="detailHero" />
          <div class="map-section mobile" v-if="showMobileMap && !loading">
            <GardenMapWrapper v-if="pageUpdated" class="map-wrapper"/>
          </div>
          <div class="detail-info-wrapper" ref="detailInfoWrapper">
            <detail-about :garden="garden"/>
            <detail-tabs :garden="garden"/>
            <share-garden :garden="garden"/>
          </div>
        </b-col>
        <b-col id="map-section" class="map-section p-0" cols="6" v-if="!showMobileMap && !loading">
          <GardenMapWrapper v-if="pageUpdated" class="map-wrapper"/>
        </b-col>
      </b-row>
    </b-col>
    <b-col v-else id="spinner-wrapper" class="d-flex justify-content-center align-items-center">
      <b-spinner variant="primary" label="Loading..."></b-spinner>
    </b-col>
  </b-row>
</template>

<script>
import { mapGetters } from 'vuex'
import DetailHero from '@/components/gardenDetails/DetailHero'
import DetailAbout from '@/components/gardenDetails/DetailAbout'
import DetailTabs from '@/components/gardenDetails/DetailTabs'
import ShareGarden from '@/components/gardenDetails/ShareGarden'
import GardenMapWrapper from '@/components/map/GardenMapWrapper'

export default {
  name: 'Garden',

  components: {
    DetailHero,
    DetailAbout,
    DetailTabs,
    ShareGarden,
    GardenMapWrapper,
  },

  data: () => ({
    pageUpdated: false,
    isUserScrolling: null,
    garden: null,
    loading: false,
    showMobileMap: false,
  }),

  mounted() {
    this.$nextTick(function () {
      // Code that will run only after the
      // entire view has been rendered
      this.pageUpdated = true
    });

    this.setShowMobileMap();
  },

  computed: {
    ...mapGetters('garden', [
      'gardenById',
    ]),
    gardenId() {
      return this.$route.params.gardenId;
    },
  },

  async created() {
    await this.setGarden();
    this.setShowMobileMap();
    window.addEventListener('resize', this.setShowMobileMap);
  },

  destroyed() {
    window.removeEventListener('resize', this.setShowMobileMap);
  },

  watch: {
    async gardenId(value, oldValue) {
      if (value !== oldValue) {
        this.loading = true;
        await this.setGarden()
        this.loading = false;
      }
    },
  },

  methods: {
    setShowMobileMap() {
      const query = window.matchMedia('(max-width: 991px)');
      this.showMobileMap = query.matches;
    },
    async setGarden() {
      await this.$store.dispatch('garden/fetchGarden', this.gardenId);
      this.garden = await this.$store.getters['garden/garden'];
    },
  },

  updated() {
    this.$nextTick(function () {
      if (this.garden) {
        // Code that will run only after the
        // entire view has been re-rendered
        const headerHeight = document.querySelector('#header').offsetHeight

        const headerTopWrapper = document.querySelector('#top-wrapper')
        const headerTopWrapperStyle = getComputedStyle(headerTopWrapper)
        const headerTopWrapperStyleMarginTop = parseInt(headerTopWrapperStyle.marginTop, 10)

        const headerHeightTotal = headerHeight + headerTopWrapperStyleMarginTop

        const vh = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0)
        const maxHeight = (vh - headerHeightTotal) - 1;

        if (document.getElementById('map-section')) {
          document.getElementById('map-section').style.height = `${maxHeight}px`;
        }

        if (document.getElementById('detail-section')) {
          document.getElementById('detail-section').style.height = `${maxHeight}px`;
        }
      }
    })
  },
}
</script>

<style lang="scss">
$stickyHeaderHeight: 97px;

.details {
  background-color: #fff;

  #spinner-wrapper {
    height: 100vh;
  }
}
</style>

<style scoped lang="scss">
.details {
  .detail-section {
    overflow-y: scroll;

    .detail-info-wrapper {
      padding: 2rem;

      @media (max-width: 1200px) {
        padding: 1rem;
      }
    }
  }

  .map-section {
    .map-wrapper {
      height: 100%;
    }

    img {
      width: 100%;
    }

    &.mobile {
      .map-wrapper {
        height: 150px;
      }
    }
  }
}
</style>
