<template>
  <div class="detail-about-wrapper">
    <div class="mobile-garden-details d-block d-xl-none">
      <GardenGroupInfo :garden="garden" mobile/>
      <div class="garden-name">
        <h2 class="m-0">{{ garden.name }}</h2>
      </div>
      <div class="garden-location">
        <h5 class="m-0">{{ gardenLocation }}</h5>
      </div>
    </div>
    <h3>About</h3>
    <p>{{ garden.description }}</p>
  </div>
</template>

<script>
import GardenGroupInfo from '@/components/gardenDetails/GardenGroupInfo';

export default {
  name: 'DetailAbout',
  components: {
    GardenGroupInfo,
  },
  props: {
    garden: Object,
  },

  computed: {
    gardenGroup() {
      if (!this.garden.same_group_gardens) {
        return [];
      }

      const gardens = this.garden.same_group_gardens.map(({ name }) => { return { name } });
      gardens.push({ name: this.garden.name });

      return gardens;
    },
    gardenLocation() {
      const gardenLocation = [];

      gardenLocation.push(this.garden.address_2 || null);
      gardenLocation.push(this.garden.address_3 || null);
      gardenLocation.push(this.garden.town || null);
      gardenLocation.push(this.garden.county || null);
      gardenLocation.push(this.garden.postcode || null);

      return gardenLocation.filter((a) => a)
        .join(', ');
    },
  },
}
</script>

<style scoped lang="scss">
.mobile-garden-details {
  margin-bottom: 1rem;

  .garden-location {
    color: #676767;
  }
}

.detail-about-wrapper {
  margin-bottom: 1.526rem;

  .group-dropdown-wrapper {
    margin-bottom: .5rem;

    * {
      text-transform: uppercase;
      color: #000;
    }

    svg {
      font-size: 12px !important;
      border-radius: 7px;
      background: black;

      path {
        color: $primary;
      }
    }
  }

  .show-full-description {
    cursor: pointer;
  }

  .next-booking-info {
    color: $color-marine-main;
    font-size: 0.875rem;

    .next-booking-info-text {
      margin-left: 0.546rem;
      font-style: italic;
    }
  }
}
</style>
