<template>
  <div class="open-days-accordion-wrapper" v-if="openings">
    <div class="opening-times-title mb-1">
      <h3>Open Days</h3>
    </div>
    <div class="mb-4">
      Many gardens are now offering cashless payment - if they are they will show the
      <ngs-icon :tag="findIconByTag('cashless')"/>
      icon. Otherwise payment is by cash.
    </div>
    <div class="mb-4" v-if="garden.additional">
      {{ garden.additional }}
    </div>
    <div class="accordion" role="tablist">
      <b-card
        no-body
        class="mb-1"
        v-for="(opening, openingIndex) in openings"
        :key="openingIndex"
      >
        <b-card-header header-tag="header" class="p-0" role="tab">
          <b-button block
            v-b-toggle="'open-days-accordion-'+openingIndex"
            variant="secondary"
            class="w-100 d-flex justify-content-start align-items-center accordion-button"
          >
            <span>{{ gardenEventDates(opening) }}</span>
            <b-button
              v-if="opening.canceled"
              size="sm"
              class="ml-2"
              variant="danger"
            >
              Cancelled
            </b-button>
            <b-button
              v-if="!opening.canceled && (opening.prebookingthroughgarden || opening.prebookingthroughngs)"
              size="sm"
              class="ml-2 prebooking-button"
            >
              Pre-booking Essential
            </b-button>
            <font-awesome-icon class="ml-auto right-icon" :icon="['far', 'chevron-down']"/>
          </b-button>
        </b-card-header>
        <b-collapse :id="'open-days-accordion-'+openingIndex"
          class="accordion-item"
          visible
          accordion="open-days-accordion"
          role="tabpanel"
        >
          <b-card-body>
            <b-card-text>
              <b-row>
                <b-col cols="12" v-if="opening.prebookingavailable" class="mb-2">
                  For this open day you can book your tickets in advance.
                  Click on the yellow button below to book or you can just turn up and pay on the day.
                </b-col>
                <b-col cols="12" v-if="opening.prebookingthroughgarden" class="mb-2">
                  Pre-booking essential, please contact the garden owner (details within the Owner Info tab) to book.
                </b-col>
                <b-col cols="12" v-if="opening.prebookingthroughngs" class="mb-2">
                  For this open day you need to pre-book your ticket in advance. To book tickets please click on the book button below.
                </b-col>
                <b-col class="d-flex flex-column" v-if="opening.refreshments">
                  <b>Refreshments</b>
                  <span>{{ opening.refreshments }}</span>
                </b-col>
                <b-col
                  v-if="adultAdmissionFee(opening) || childAdmissionFee(opening)"
                  class="d-flex flex-column"
                >
                  <b>Admission</b>
                  <div v-if="isGop(opening)">
                    <span>
                      {{ opening.commercial }}
                    </span>
                  </div>
                  <div v-else class="d-flex flex-column">
                    <span v-text="adultAdmissionFee(opening)"/>
                    <span v-text="childAdmissionFee(opening)"/>
                  </div>
                </b-col>
                <b-col class="d-flex flex-column">
                  <b>Opening times</b>
                  <span>{{ opening.start_date | moment("HH:mm")  }} - {{ opening.end_date | moment("HH:mm")  }}</span>
                </b-col>
                <b-col cols="12" v-if="garden.donation" class="mt-2">
                  <b>Donations</b>
                  <p>{{ garden.donation }}</p>
                </b-col>
                <b-col cols="12" v-if="isParentGarden" class="mb-4">
                  <b>Gardens open on this day as part of this group</b>
                  <p>
                    For group openings you pay one admission price and you get entry to all the gardens on that day. See below for
                    gardens that are part of this group. Please note that sometimes not all gardens open on every open day, check
                    the dates above for more info.
                  </p>
                  <features-carousel
                    v-if="groupGardens.length"
                    :groupGardens="getOpeningGardens(opening)"
                    class="mt-4"
                    @changed-garden="$emit('changed-garden')"
                  />
                </b-col>
                <b-col cols="12" v-if="opening.combined_garden" class="combined-garden-wrapper mt-2 mb-4">
                  <b>This garden opens with the one below</b>
                  <p>You pay one admission price and get entry to both gardens on that day</p>
                  <div>
                    <b-card
                      :img-src="opening.combined_garden.images[0]"
                      img-top
                      style="max-width: 20rem;"
                      class="mb-2"
                    >
                      <b-row>
                        <b-col class="garden-name">
                          <h4>{{ opening.combined_garden.name }}</h4>
                        </b-col>
                      </b-row>
                      <b-row>
                        <b-col class="d-flex justify-content-end">
                          <b-button
                            class="ngs-font"
                            variant="primary"
                            size="lg"
                            @click="goToGarden(opening.combined_garden)"
                          >
                            Details
                          </b-button>
                        </b-col>
                      </b-row>
                    </b-card>
                  </div>
                </b-col>
                <b-col
                  cols=12
                  class="d-flex justify-content-end align-items-end"
                  v-if="!opening.canceled && (opening.prebookingthroughngs || opening.prebookingavailable)"
                >
                  <b-button
                    class="ngs-font"
                    variant="primary"
                    size="lg"
                    :href="bookingUrl(opening.guid)"
                    target="_blank"
                  >
                    Book
                  </b-button>
                </b-col>
              </b-row>
            </b-card-text>
          </b-card-body>
        </b-collapse>
      </b-card>
    </div>
  </div>
</template>

<script>
import { snakeCase } from 'lodash';
import { mapGetters } from 'vuex';
import FeaturesCarousel from '@/components/gardenDetails/detailTabs/features/FeaturesCarousel';
import NgsIcon from '@/components/ngsIcons/NgsIcon';
import DataFormattingMixin from '@/components/mixins/DataFormattingMixin';
import {
  GARDEN_OPENING_TYPE_ID_OPENING_WITHIN_GROUP,
  GARDEN_OPENING_TYPE_ID_GOP_NO_NGS_DATES,
} from '@/constants'

export default {
  name: 'OpenDaysAccordion',
  mixins: [DataFormattingMixin],
  props: {
    openings: {
      type: Array,
      require: true,
    },
    garden: {
      type: Object,
      require: true,
    },
  },

  components: {
    FeaturesCarousel,
    NgsIcon,
  },

  computed: {
    ...mapGetters('ngsicons', [
      'findIconByTag',
    ]),
    isParentGarden() {
      return this.garden.garden_type_id === 2;
    },
    groupGardens() {
      if (this.garden.group_gardens.length) {
        return this.garden.group_gardens;
      }

      if (this.garden.same_group_gardens.length) {
        return this.garden.same_group_gardens;
      }

      return [];
    },
  },

  methods: {
    isGop(opening) {
      return opening.garden_opening_type_id === GARDEN_OPENING_TYPE_ID_GOP_NO_NGS_DATES;
    },
    adultAdmissionFee(opening) {
      if (opening.price_adult == null) {
        return '';
      }

      if (opening.price_adult <= 0) {
        return 'Adult: Admission by Donation';
      }

      return `Adult: ${this.formatPrice(opening.price_adult)}`;
    },
    childAdmissionFee(opening) {
      if (opening.price_child == null) {
        return '';
      }

      if (opening.price_child <= 0) {
        return 'Child: Free';
      }

      return `Child: ${this.formatPrice(opening.price_child)}`
    },
    getOpeningGardens(opening) {
      // Only returns gardens which have a GROUP opening on that very same day
      const groupGardens = this.groupGardens.filter((garden) => {
        garden.allOpeningsCanceled = true;

        const localOpenings = garden.openings.filter((gardenOpening) => {
          if (!gardenOpening.canceled) {
            garden.allOpeningsCanceled = false;
          }

          return gardenOpening.garden_opening_type_id === GARDEN_OPENING_TYPE_ID_OPENING_WITHIN_GROUP
            && gardenOpening.start_date === opening.start_date
            && gardenOpening.end_date === opening.end_date;
        });

        return localOpenings.length > 0;
      });

      return groupGardens;
    },
    async goToGarden(garden) {
      await this.$store.dispatch('garden/fetchGarden', garden.id);
      this.$router.push({ name: 'Garden', params: { gardenId: garden.id, gardenName: snakeCase(garden.name) } })
    },
    bookingUrl(openingGuid) {
      return `${process.env.VUE_APP_BOOKING_URL}${openingGuid}/`;
    },
  },
}
</script>

<style lang="scss">

  .accordion-button {
    font-weight: 600;
    background-color: $color-yellow-light !important;
    border: none !important;
  }

  .accordion-item {
    background-color: $color-yellow-lighter;
  }
</style>
