<template>
  <div class="features-carousel-wrapper" v-if="groupGardens.length">
      <VueSlickCarousel v-bind="settings">
        <b-card
          v-for="(groupGarden, index) in groupGardens"
          :key="index"
          :img-src="groupGarden.images[0]"
          img-alt="Image"
          no-body
          img-top
        >
          <img-placeholder class="image-placeholder" v-if="!groupGarden.images[0]"/>
          <b-card-body>
            <b-row>
              <b-col class="garden-name">
                <h4>{{ groupGarden.name }}</h4>
              </b-col>
            </b-row>
            <b-row>
              <b-col class="d-flex align-items-center justify-content-between">
                <span v-if="groupGarden.allOpeningsCanceled" class="cancelled-label">Cancelled</span>
                <b-button
                  class="ngs-font ml-auto"
                  variant="primary"
                  size="lg"
                  @click="goToParentGarden(groupGarden.id, groupGarden.name)"
                >
                  Details
                </b-button>
              </b-col>
            </b-row>
          </b-card-body>
        </b-card>
        <template #prevArrow>
          <div class="round-icon-button-wrapper prev">
            <b-button class="round-icon-button" pill variant="outline-primary">
              <font-awesome-icon
                :icon="['fas', 'arrow-left']"/>
            </b-button>
            </div>
        </template>
        <template #nextArrow>
          <div class="round-icon-button-wrapper next">
            <b-button class="round-icon-button" pill variant="outline-primary">
              <font-awesome-icon
                :icon="['fas', 'arrow-right']"/>
            </b-button>
            </div>
        </template>
      </VueSlickCarousel>
  </div>
</template>

<script>

import snakeCase from 'lodash';
import VueSlickCarousel from 'vue-slick-carousel';
import 'vue-slick-carousel/dist/vue-slick-carousel.css';
import ImgPlaceholder from '@/components/images/imgPlaceholder';

export default {
  name: 'FeaturesCarousel',

  props: {
    groupGardens: Array,
  },

  components: {
    ImgPlaceholder,
    VueSlickCarousel,
  },

  data() {
    return {
      settings: {
        dots: false,
        infinite: false,
        centerPadding: '20px',
        slidesToShow: 1,
        slidesToScroll: 1,
        variableWidth: true,
      },
      tags: [
        'wheelchair',
        'dogs',
        'teas',
        'vegetable-area',
        'countryside-views',
        'wildlife-areas',
        'cashless',
      ],
    }
  },

  methods: {
    async goToParentGarden(gardenId, gardenName) {
      await this.$store.dispatch('garden/fetchGarden', gardenId);
      this.$emit('changed-garden');
      this.$router.push({ name: 'Garden', params: { gardenId, gardenName: snakeCase(gardenName) } })
    },
  },
}
</script>

<style lang="scss">
  .features-carousel-wrapper {
    .slick-slider {
      .round-icon-button-wrapper {
        &::before {
          content: none;
        }
      }
    }
  }

  .combined-garden-wrapper,
  .features-carousel-wrapper {
    .slick-slider {
      position: relative;

      .round-icon-button-wrapper {
        position: absolute;
        z-index: 1;
        display: flex;
        justify-content: center;
        align-items: center;

        &.slick-prev {
          left: 15px;
        }

        &.slick-next {
          right: 15px;
        }

        &.slick-disabled {
          display: none;
        }

        .round-icon-button {
          width: 38px;
          height: 38px;
          color: #000;
          background: white;
          border-radius: 50rem !important;
          border: 1px solid black;
          box-shadow: none !important;
        }
      }
    }

    .card {
      margin-right: 15px;
      //width: 251px !important; // design width
      width: 270px !important;
      border-radius: 0;

      > img {
        height: 148px;
        width: 100%;
        object-fit: cover;
        border-radius: 0;
      }
    }

    .card-title {
      font-size: 13px;
    }
  }
</style>
