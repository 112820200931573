<script>
import { mapGetters, mapMutations } from 'vuex'
import {
  filter,
  includes,
} from 'lodash'
import { markerIcons } from '@/components/map/constants/mapSettings';
import { GARDEN_OPENING_TYPE_IDS_OPENING, GARDEN_OPENING_TYPE_IDS_ARRANGEMENT } from '@/constants'

export default {
  name: 'GoogleMapMarker',

  props: {
    google: {
      type: Object,
      required: true,
    },
    map: {
      type: Object,
      required: true,
    },
    garden: {
      type: Object,
      required: true,
    },
  },

  mounted() {
    this.$nextTick(function () {
      if (this.map && this.garden) {
        this.setMarker()
      }
    })
  },

  render() {},

  computed: {
    ...mapGetters('map', [
      'mapBounds',
    ]),
  },

  watch: {
    mapBounds(value) {
      if (value) {
        this.setMarker()
      }
    },
  },

  methods: {
    ...mapMutations('map', [
      'addMarker',
    ]),

    setMarker() {
      const isGroup = this.garden.garden_type_id === 2

      const hasOpenings = filter(this.garden.openings, function (opening) {
        return includes(GARDEN_OPENING_TYPE_IDS_OPENING, opening.garden_opening_type_id)
      }).length > 0;

      const hasArrangement = filter(this.garden.openings, function (opening) {
        return includes(GARDEN_OPENING_TYPE_IDS_ARRANGEMENT, opening.garden_opening_type_id)
      }).length > 0;

      const hasOpeningsAndArrangement = (hasOpenings && hasArrangement) ?? false;

      let markerType = hasOpeningsAndArrangement ? 'markerOpeningAndArrangement'
        : hasOpenings ? 'markerOpening'
          : hasArrangement ? 'markerArrangement'
            : null
      if (isGroup && !hasOpeningsAndArrangement) {
        markerType = `${markerType}Group`
      }

      const now = new Date()
      const openings = filter(this.garden.openings, function (opening) {
        return new Date(opening.end_date) >= now
      })
      if (!openings.length) {
        markerType = 'markerClosed'
      }
      const markerIcon = {
        url: markerIcons[markerType],
        scaledSize: new this.google.maps.Size(46.5, 46.5), // scaled size
      }

      this.addMarker(
        new this.google.maps.Marker({
          id: this.garden.id,
          position: {
            lat: parseFloat(this.garden.latitude),
            lng: parseFloat(this.garden.longitude),
          },
          map: this.map,
          icon: markerIcon,
        }),
      )
    },
  },
};
</script>
