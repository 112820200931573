<template>
  <div class="detail-tabs-wrapper">
    <div class="detail-tabs-wrapper__chevron-container">
      <font-awesome-icon
        class="detail-tabs-wrapper__chevron-container--icon"
        :icon="['far', 'chevron-right']"
        @click="handleChevronClick"
      />
    </div>
    <b-tabs v-model="activeTab">
      <b-tab title="Openings">
        <OpeningTimes :garden="garden"/>
      </b-tab>
      <b-tab title="Features">
        <Features :garden="garden" @changed-garden="resetActiveTab"/>
      </b-tab>
      <b-tab title="Accessibility">
        <h3>Accessibility</h3>
        {{ accessibilityText }}
      </b-tab>
      <b-tab title="Find us">
        <h3>Find us</h3>
        <span v-for="(string, index) in findUs" :key="index">
          {{ string }} <br>
        </span>
      </b-tab>
      <b-tab title="Owner info">
        <h3>Owner info</h3>
        <span v-for="(string, index) in ownerInfo" :key="index">
          {{ string }} <br>
        </span>
        <span class="owner-url" v-if="garden.published_website">
          <a :href="garden.published_website">{{ garden.published_website }}</a>
        </span>
      </b-tab>
      <b-tab title="Virtual Garden Visit" v-if="garden.videos && garden.videos.length">
        <Videos :garden="garden" @changed-garden="resetActiveTab"/>
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import OpeningTimes from '@/components/gardenDetails/detailTabs/openings/OpeningTimes'
import Features from '@/components/gardenDetails/detailTabs/features/Features'
import Videos from '@/components/gardenDetails/detailTabs/features/Videos'

export default {
  name: 'DetailTabs',

  components: {
    OpeningTimes,
    Features,
    Videos,
  },

  props: {
    garden: Object,
  },

  data() {
    return {
      activeTab: 0,
    }
  },

  computed: {
    accessibilityText() {
      return this.garden.disability_access;
    },
    findUs() {
      const findUsArray = [];

      if (this.garden.name) {
        findUsArray.push(this.garden.name);
      }
      if (this.garden.address_2) {
        findUsArray.push(this.garden.address_2);
      }
      if (this.garden.address_3) {
        findUsArray.push(this.garden.address_3);
      }
      if (this.garden.town) {
        findUsArray.push(`${this.garden.town},`);
      }
      if (this.garden.county) {
        findUsArray.push(this.garden.county);
      }
      if (this.garden.postcode) {
        findUsArray.push(this.garden.postcode);
      }
      if (this.garden.directions_reference) {
        findUsArray.push('\n');
        findUsArray.push(this.garden.directions_reference);
      }
      if (this.garden.directions_full) {
        findUsArray.push(this.garden.directions_full);
      }

      return findUsArray;
    },
    ownerInfo() {
      const ownerInfoArray = [];

      if (this.garden.published_name) {
        ownerInfoArray.push(this.garden.published_name);
      }
      if (this.garden.published_email) {
        ownerInfoArray.push(this.garden.published_email);
      }
      if (this.garden.published_phone) {
        ownerInfoArray.push(this.garden.published_phone);
      }

      return ownerInfoArray;
    },
  },

  methods: {
    resetActiveTab() {
      this.activeTab = 0;
    },
    handleChevronClick() {
      const scrollWrapper = document.querySelector('.detail-tabs-wrapper > .tabs > div');

      if (scrollWrapper) scrollWrapper.scrollLeft = 500;
    },
  },
}
</script>

<style lang="scss">
  .owner-url a {
    color: #000;
  }

  .detail-tabs-wrapper {
    margin-right: -2rem;
    margin-left: -2rem;
    position: relative;

    @media (max-width: 1200px) {
      margin-right: -1rem;
      margin-left: -1rem;
    }

    > .tabs {
      > div {
        overflow-x: auto;

        > ul.nav-tabs {
          padding-left: 2rem !important;
          min-width: 559px;

          @media (max-width: 1200px) {
            padding-left: 1rem !important;
          }
        }
      }
    }

    .tab-content {
      .tab-pane {
        padding: 2rem;

        @media (max-width: 1200px) {
          padding: 1rem;
        }
      }
    }

    &__chevron-container {
      display: none;

      @media (max-width: 592px) {
        display: block;
        position: absolute;
        right: 0;
        background-color: white;

        &--icon {
          margin: 16px 10px 0;
        }
      }
    }
  }
</style>
