<template>
  <div class="features-wrapper">
    <b-row>
      <b-col>
        <h3>Features</h3>
      </b-col>
      <b-col class="d-flex justify-content-end">
        <ngs-icon-group :tags="garden.tags"/>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12" v-if="features.length">
        <div v-for="(string, index) in features" :key="index">
          <h3 v-if="string.title">{{ string.title }}</h3>
          <p>{{ string.content }}</p>
        </div>
      </b-col>
      <b-col cols="12" v-if="garden.press_coverage">
        <h3>Media Coverage</h3>
        <p>{{ garden.press_coverage }}</p>
      </b-col>
      <b-col cols="12" v-if="garden.garden_type_id === 2">
        <h3>Gardens open as part of this group</h3>
        <span>For group openings you pay one admission price and get entry to all the gardens on that day. See below for further information on gardens that are part of this group. Please note that not all gardens open on every open day.</span>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <features-carousel
          v-if="groupGardens.length"
          :groupGardens="groupGardens"
          class="mt-4"
          @changed-garden="$emit('changed-garden')"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>

import NgsIconGroup from '@/components/ngsIcons/NgsIconGroup';
import FeaturesCarousel from '@/components/gardenDetails/detailTabs/features/FeaturesCarousel'

export default {
  name: 'Features',

  props: {
    garden: Object,
  },

  components: {
    NgsIconGroup,
    FeaturesCarousel,
  },

  computed: {
    groupGardens() {
      if (this.garden.group_gardens.length) {
        return this.garden.group_gardens;
      }

      if (this.garden.same_group_gardens.length) {
        return this.garden.same_group_gardens;
      }

      return [];
    },
    features() {
      const features = [];

      if (this.garden.features_attractions) {
        features.push({ content: this.garden.features_attractions });
      }

      if (this.garden.description_extended) {
        features.push({
          title: 'Extended Description',
          content: this.garden.description_extended,
        });
      }

      return features;
    },
  },
}
</script>

<style scoped lang="scss">
</style>
