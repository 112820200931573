// eslint-disable-next-line import/no-extraneous-dependencies
import moment from 'moment';

export default {
  methods: {
    formatPrice(price) {
      return `£${price}`;
    },
    gardenEventDates(opening) {
      const startDate = moment(opening.start_date).format('dddd D MMM YYYY')

      if (opening.end_date) {
        const endDate = moment(opening.end_date).format('dddd D MMM YYYY')

        if (startDate !== endDate) {
          return `${startDate} - ${endDate}`;
        }
      }
      return startDate;
    },
  },
};
