<template>
  <div class="opening-card-wrapper">
    <b-card>
      <b-card-text>
        <b-row>
          <b-col cols="12">
            <slot name="card-description"></slot>
          </b-col>
        </b-row>
        <b-row>
          <b-col v-if="!hideRefreshments && opening.refreshments && !opening.canceled" class="d-flex flex-column mt-4">
            <b>Refreshments</b>
            <span>{{ opening.refreshments }}</span>
          </b-col>
          <b-col v-if="!opening.canceled && adultAdmissionFee(opening)" class="d-flex flex-column mt-4">
            <b>Admission</b>
            <div v-if="hideAdmissionSection">
              <span>
                Admission by donation
              </span>
            </div>
            <div v-else class="d-flex flex-column">
              <span v-text="adultAdmissionFee(opening)"/>
              <span v-text="childAdmissionFee(opening)"/>
            </div>
          </b-col>
          <b-col v-if="!hideOpeningTimes" class="d-flex flex-column">
            <b>Opening times</b>
            <span>{{ opening.start_date | moment("HH:mm")  }} - {{ opening.end_date | moment("HH:mm")  }}</span>
          </b-col>
          <b-col cols=12 class="d-flex justify-content-end align-items-end">
            <slot name="card-button"></slot>
          </b-col>
        </b-row>
      </b-card-text>
    </b-card>
  </div>
</template>

<script>
import DataFormattingMixin from '@/components/mixins/DataFormattingMixin';
import {
  GARDEN_OPENING_TYPE_ID_ALSO_BY_ARRANGEMENT,
  GARDEN_OPENING_TYPE_ID_BY_ARRANGEMENT_ONLY,
  GARDEN_OPENING_TYPE_ID_OPENING_WITHIN_GROUP,
} from '@/constants'

export default {
  name: 'OpeningCard',
  mixins: [DataFormattingMixin],
  props: {
    title: String,
    opening: {
      type: Object,
      required: true,
    },
    hideRefreshments: {
      type: Boolean,
      default: false,
    },
    hideAdmission: {
      type: Boolean,
      default: false,
    },
    hideOpeningTimes: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
    }
  },
  computed: {
    isByArrangement() {
      return [
        GARDEN_OPENING_TYPE_ID_ALSO_BY_ARRANGEMENT,
        GARDEN_OPENING_TYPE_ID_BY_ARRANGEMENT_ONLY,
      ].includes(this.opening.garden_opening_type_id);
    },
    hideAdmissionSection() {
      if (this.opening.garden_opening_type_id === GARDEN_OPENING_TYPE_ID_OPENING_WITHIN_GROUP) {
        return true;
      }

      // ALWAYS hide the Admission Section if this is a by arrangement opening with no prices set
      if (this.isByArrangement
        && (this.opening.price_adult <= 0 && this.opening.price_child <= 0)
      ) {
        return true;
      }

      return this.hideAdmission;
    },
  },
  methods: {
    adultAdmissionFee(opening) {
      if (opening.price_adult == null) {
        return null;
      }

      if (opening.price_adult <= 0) {
        return 'Adult: Admission by Donation';
      }

      return `Adult: ${this.formatPrice(opening.price_adult)}`;
    },
    childAdmissionFee(opening) {
      if (opening.price_child == null) {
        return null;
      }

      if (opening.price_child <= 0) {
        return 'Child: Free';
      }

      return `Child: ${this.formatPrice(opening.price_child)}`
    },
  },
}
</script>
