<template>
  <div class="group-openings-accordion-wrapper" v-if="openings">
    <h3>Group Openings</h3>
    <div class="accordion" role="tablist">
      <b-card
        no-body
        class="mb-1"
        v-for="(opening, openingIndex) in openings"
        :key="openingIndex"
      >
        <b-card-header header-tag="header" class="p-0" role="tab">
          <b-button block
            v-b-toggle="'group-openings-accordion-'+openingIndex"
            variant="secondary"
            class="w-100 d-flex justify-content-start align-items-center accordion-button"
          >
            <span>{{ gardenEventDates(opening) }}</span>
            <b-button
              v-if="opening.canceled"
              size="sm"
              class="ml-2"
              variant="danger"
            >
              Cancelled
            </b-button>
            <font-awesome-icon class="ml-auto right-icon" :icon="['far', 'chevron-down']"/>
          </b-button>
        </b-card-header>
        <b-collapse :id="'group-openings-accordion-'+openingIndex"
          class="accordion-item"
          visible
          accordion="group-openings-accordion"
          role="tabpanel"
        >
          <b-card-body>
            <b-card-text>
              <b-row class="mb-4">
                <b-col cols="12">
                  A group opening is when a number of gardens open together on the same day.<br>
                  This garden opens as part of {{ parentGardenName }}.
                </b-col>
              </b-row>
              <b-row>
                <b-col class="d-flex flex-column" v-if="opening.refreshments">
                  <b>Refreshments</b>
                  <span>{{ opening.refreshments }}</span>
                </b-col>
                <b-col class="d-flex flex-column">
                  <b>Admission</b>
                    <span v-text="adultAdmissionFee(opening)"/>
                    <span v-text="childAdmissionFee(opening)"/>
                </b-col>
                <b-col class="d-flex flex-column">
                  <b>Opening times</b>
                  <span>{{ opening.start_date | moment("HH:mm")  }} - {{ opening.end_date | moment("HH:mm")  }}</span>
                </b-col>
                <b-col
                  cols=12
                  class="d-flex justify-content-end align-items-end mt-3"
                  v-if="!opening.canceled">
                  <b-button
                    class="ngs-font"
                    variant="primary"
                    size="lg"
                    @click="goToParentGarden"
                  >
                    Visit {{ parentGardenName }}
                  </b-button>
                </b-col>
              </b-row>
            </b-card-text>
          </b-card-body>
        </b-collapse>
      </b-card>
    </div>
  </div>
</template>

<script>
import snakeCase from 'lodash';
import DataFormattingMixin from '@/components/mixins/DataFormattingMixin';

export default {
  name: 'GroupOpeningsAccordion',
  mixins: [DataFormattingMixin],
  props: {
    openings: Array,
    parentGarden: Object,
  },

  computed: {
    parentGardenName() {
      if (this.parentGarden) {
        return this.parentGarden.name;
      }

      return '';
    },
  },

  methods: {
    adultAdmissionFee(opening) {
      if (opening.price_adult == null) {
        opening = this.getRelatedParentOpening(opening);

        if (!opening || opening.price_adult == null) {
          return '';
        }
      }

      if (opening.price_adult <= 0) {
        return 'Adult: Admission by Donation';
      }

      return `Adult: ${this.formatPrice(opening.price_adult)}`;
    },
    childAdmissionFee(opening) {
      if (opening.price_child == null) {
        opening = this.getRelatedParentOpening(opening);

        if (!opening || opening.price_child == null) {
          return '';
        }
      }

      if (opening.price_child <= 0) {
        return 'Child: Free';
      }

      return `Child: ${this.formatPrice(opening.price_child)}`
    },
    getRelatedParentOpening(opening) {
      return this.parentGarden.openings.find((item) => item.start_date === opening.start_date && item.end_date === opening.end_date);
    },
    async goToParentGarden() {
      await this.$store.dispatch('garden/fetchGarden', this.parentGarden.id);
      this.$router.push({ name: 'Garden', params: { gardenId: this.parentGarden.id, gardenName: snakeCase(this.parentGarden.name) } })
    },
  },
}
</script>

<style lang="scss">
  .accordion-button {
    font-weight: 600;
    background-color: $color-yellow-light !important;
    border: none !important;
  }

  .accordion-item {
    background-color: $color-yellow-lighter;
  }
</style>
