<template>
  <div class="videos-wrapper">
    <b-row>
      <b-col>
        <h3>Virtual Garden Visit</h3>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12">
        <p>During the lockdowns of 2020/21 our ever-resourceful garden owners created films of their gardens so we could keep the garden gates open online. We've kept a library of these - which we are adding new content to all the time - so that whatever the time of year you can go on a virtual garden visit and be inspired.</p>
      </b-col>
      <b-col
        cols="12"
        class="video-wrapper"
        v-if="garden.videos.length"
      >
        <div v-for="(video, index) in garden.videos" :key="index">
          <iframe
            class="video-iframe"
            :src="`https://www.youtube.com/embed/${video.id}`"
            :title="`Garden Visit #${index}`"
            frameborder="0"
            allowfullscreen />
        </div>
      </b-col>
    </b-row>

  </div>
</template>

<script>

export default {
  name: 'Videos',

  props: {
    garden: Object,
  },
}
</script>

<style lang="scss">
  .video-wrapper {
    display: flex;
    justify-content: center;

    .video-iframe {
      width: 560px;
      height: 315px;
    }
  }
</style>
